<template>
    <v-container>
      <v-row style="padding-right: 20px">
        <v-spacer></v-spacer>
        <v-btn
            color="primary"
            @click="submitMatch"
            :disabled="matchRunning"
        >
          Match Data
        </v-btn>
      </v-row>
      <v-progress-linear v-if="matchRunning" indeterminate />
      <v-row class="text-center">
        <!-- file selector -->
        <v-col cols="4">
          <v-card>
                <v-card-title>
                  Files
                </v-card-title>
                <v-card-text>
                  <v-autocomplete
                    :items="importedFiles"
                    v-model="selected"
                    label="File Selection"
                    :filter="fileFilter"
                  >
                    <template v-slot:selection="data" style="padding-top: 5px">
                      {{ data.item.filename }}
                      <v-spacer />
                      <small>
                        {{ data.item.created }}
                      </small>
                    </template>
                    <template v-slot:item="data">
                      <v-list-item-content>
                        <b>{{ data.item.filename }}</b>
                        <br>
                        <v-row style="padding-left: 20px; padding-right:20px">
                          <small>
                            14 Records
                          </small>
                          <!-- TODO: size, rows, etc.? -->
                          <v-spacer />
                          <small>
                            {{ data.item.created }}
                          </small>
                        </v-row>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-card-text>
              </v-card>
            </v-col>
            <!-- select account, review data -->
            <v-col cols="4">
                      <v-row>
                        <v-card style="width: 100%; margin-bottom: 20px;">
                          <v-card-title>Account</v-card-title>
                          <v-card-text>
                            <v-autocomplete
                                v-model="account"
                                :items="accounts"
                                item-text="name"
                                item-value="accountid"
                                label="Account"
                            >
                              <!--
                              <template v-slot:selection="data">
                                  <template v-if="typeof data.item !== 'object'">
                                      <v-list-item-content v-text="data.item"></v-list-item-content>
                                  </template>
                                  <template v-else>
                                      <v-list-item-content>
                                          {{ data.item.name }}
                                      </v-list-item-content>
                                  </template>
                              </template>
                              <template v-slot:item="data">
                                  <template v-if="typeof data.item !== 'object'">
                                      <v-list-item-content v-text="data.item"></v-list-item-content>
                                  </template>
                                  <template v-else>
                                      <v-list-item-content>
                                          {{ data.item.name }}
                                      </v-list-item-content>
                                  </template>
                              </template>
                              -->
                            </v-autocomplete>
                          </v-card-text>
                        </v-card>
                      </v-row>
                      <v-row>
                        <column-classifier
                            group-name="hello"
                            :columns="columns"
                            v-on:change="setColumnDefinition"
                        />
                      </v-row>
                      <v-row>
                        <import-options
                            :options="matchOptions"
                            v-on:change="setMatchOptions"
                        />
                      </v-row>
            </v-col>
          <!-- select previous match definitions -->
          <v-col cols="4">
            <v-card>
              <v-card-title>
                Saved Match Definitions
              </v-card-title>
              <v-card-text>
                Coming Soon!
              </v-card-text>
            </v-card>
            <v-card style="margin-top: 20px">
              <v-card-title>
                Preview Records
              </v-card-title>
              <v-card-text>
                Coming Soon!
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      <v-progress-linear v-if="matchRunning" indeterminate />
    </v-container>
</template>

<script>
import ColumnClassifier from "@/components/ColumnClassifier";
import ImportOptions from "@/components/ImportOptions";
import {Action, Getter} from "@/store/importHelper/types";
export default {
  name: 'ReviewFile',
  components: {ImportOptions, ColumnClassifier},
  props: {
    importedFiles: Array,
    accounts: Array,
  },
  computed: {
    matchRunning() {
      return this.$store.getters[`${[Getter.GetUploadFileMatchRunning]}`]
    },
    matchOptions() {
      return this.$store.getters[`${[Getter.GetMatchOptions]}`]
    },
    columns() {
      console.log(this.selected)
      if (this.selected.uploadfilemeta && this.selected.uploadfilemeta[0]) {
        let meta = this.selected.uploadfilemeta[0]
        console.log(meta)
        let colNames = meta.names.map(
            (name) => {
              return {"name": name}
            }
        )
        return colNames
      }
      else return []
    },
  },
  methods: {
    setMatchOptions: function(input) {
      console.log(input)
      this.selectedMatchOptions = input
    },
    setColumnDefinition: function(input) {
      console.log(input)
      let modifiedInput = input.reduce(
          (o, elt) => {
            let key = elt.name
            let val = elt.selected
            if (val) {
              return Object.assign(o, {[key]: val})
            }
            return o
          },
          {}
      )
      console.log(modifiedInput)
      this.columnDefinition = modifiedInput
    },
    initialize: function() {
      this.$store.dispatch(`${[Action.GetMatchOptions]}`)
    },
    fileFilter: function (item, queryText, itemText) {
      return itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
    },
    submitMatch: function() {
      // TODO: error if invalid selections / bad request / etc...
      let payload = {
        uploadfileid: this.selected.uploadfileid,
        accountid: this.account,
        columns: this.columnDefinition,
        matchoptions: this.selectedMatchOptions,
      }
      console.log(payload)
      let res = this.$store.dispatch(`${[Action.CheckUploadFileMatches]}`, payload)

      res.then(
          () => {
            this.$emit('submit', {accountid: this.account})
          }
      )
    },
  },
  mounted() {
    this.initialize()
  },
  data: () => ({
    selected: [],
    account: [],
    columnDefinition: {},
    selectedMatchOptions: [],
    accountsOld: [
        {
            "accountid": 1,
            "name": "Citi"
        },
        {
            "accountid": 2,
            "name": "Wells Fargo"
        }
    ],
    importedFilesOld: [
        {
            guid: "1234-5678",
            filename: "test-hi.csv",
            active: false,
            size: 7456,
            rows: 2,
            lastModified: "2020-10-05 01:02:03",
            lastReferenced: "2020-10-05 01:02:03",
        }, {
            guid: "9999-8888",
            filename: "another.csv",
            active: true,
            size: 1234,
            rows: 4,
            lastModified: "2020-01-10 01:02:03",
            lastReferenced: "2020-01-10 01:02:03",
        }
    ]
  })
}
</script>
