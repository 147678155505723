<template>
<v-card class="category-list">
<v-list>
    <v-subheader><b>{{ categoryName }} ({{valueCopy.length}} items)</b></v-subheader>
  <div :style='"height: " + height + "px; overflow-y: scroll"'>
    <v-list-item-group>
      <v-container fluid dense class="category-list-container">
        <draggable
            v-model="valueCopy"
            :group="groupName"
            :style="'height: ' + height + 'px; width: 100%;'"
            class="row row--dense"
            @change="testEvent($event)"
        >
          <!--@change="$emit('update',this.valueCopy)"-->
          <template v-for="(transact, index) in valueCopy">
            <v-list-item :key="index" class="category-list-item">
              <TransactChip :transact="transact" :close="false"></TransactChip>
            </v-list-item>
          </template>
        </draggable>
      </v-container>
    </v-list-item-group>
  </div>
</v-list>
</v-card>
</template>

<script>
import draggable from 'vuedraggable'
import TransactChip from '@/components/TransactChip.vue'
export default {
    name: "CategoryList",
    components: {
        TransactChip,
        draggable
    },
    data: () => ({
      valueCopy: [],
    }),
    props: {
        value: Array,
        groupName: String,
        categoryName: String,
      height: {
        type: Number,
        default: 150,
      }
    },
    methods: {
      testEvent: function(event) {
        console.log(event)
        this.$emit('change', event)
        console.log(this.valueCopy)
        console.log(this.value)
        this.$emit('update', this.valueCopy)
      },
    },
  watch: {
      // value: function(newVal) {
      //   console.log('executing watcher')
      //   console.log(newVal)
      //   if (newVal) {
      //     this.valueCopy = newVal
      //   }
      //   return newVal
      // }
  },
  mounted() {
      if (this.value) {
        console.log('startup')
        console.log(this.value)
        this.valueCopy = this.value
      }
  }
}
</script>

<style scoped>
.v-sheet.v-card.category-list {
    margin: 10px;
}

.v-list-item-group .v-list-item--active {
    color: white;
}
</style>

<style>
.category-list-item {
    flex: unset;
    padding: 0 0 2px 2px;
  height: 38px;
}

.category-list-container {
    padding: 2px;
}
</style>
