<template>
    <v-container dense class="categorize-container">
      <v-row style="padding-right: 20px">
        <v-btn
          color="primary"
          @click="back"
          >
          Back
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
            color="primary"
            @click="save"
            :disabled="submitted || refreshing"
        >
          Save
        </v-btn>
      </v-row>
      <v-progress-linear indeterminate  v-if="submitted || refreshing" />
      <v-row>
        <v-col cols="2" />
        <v-col cols="8">
          <MonthSelector
              :allow-add="false"
              :input-months="instanceList"
              :active-month="activeInstance.instanceid"
              v-on:change="activateInstance"
              v-on:addBlank="newBlankInstance"
              v-on:addTemplate="newTemplateInstance"
          >
          </MonthSelector>
        </v-col>
        <v-col cols="2" />
      </v-row>
        <v-row class="text-center" dense>
            <v-col cols="3">
              <div style="padding: 10px">
                <v-btn @click="refresh">Refresh</v-btn>
                <v-btn @click="clearExcluded">Clear Excluded ({{ excludedTransacts.length }})</v-btn>
                <v-checkbox v-model="filterToMonth" label="Filter to Selected Month"></v-checkbox>
              </div>
                <TransactList
                    :listName="'Transactions (' + internalTransactListDisplay.length + '/' + internalTransactListLength + ' items)'"
                    groupName="transactions"
                    v-model="internalTransactListDisplay"
                    :max-height="550"
                    style="resize: vertical; overflow: auto;"
                    v-on:close="closeTransact"
                >
                </TransactList>
            </v-col>
            <v-col cols="9" style="max-height: 550px; overflow-y:scroll; margin-top: 120px; padding-bottom: 300px">
                <v-row dense>
                <template
                    v-for="(category, index) in monthlyCategoryList"
                >
                    <CategoryList
                        :key="index"
                        :categoryName=category.name
                        groupName="transactions"
                        :value="categorize[category.name]"
                        class="col col-3"
                        v-on:update="testEvent($event, category.name)"
                        v-on:change="changeHandler($event, category.name)"
                    >
                    </CategoryList>
                </template>
                </v-row>
              <v-btn @click="test">Test</v-btn>
            </v-col>
        </v-row>
        <v-progress-linear indeterminate  v-if="submitted || refreshing" />
    </v-container>
</template>

<script>
import CategoryList from '@/components/CategoryList.vue'
import TransactList from '@/components/TransactList.vue'
import MonthSelector from "@/components/MonthSelector";
import _ from 'lodash'
// import Vue from 'vue'
// import {Action as TransactAction, Getter as TransactGetter} from "@/store/transact/types";
import {Action as BudgetTransactAction} from "@/store/budgetTransact/types";
import {Action as ProfileAction, Getter as ProfileGetter} from '@/store/profile/types'
import {Action as InstanceAction, Getter as InstanceGetter} from '@/store/instance/types'
import {Action as CategoryAction, Getter as CategoryGetter} from '@/store/budgetCategory/types'
import {Action as TransactAction, Getter as TransactGetter} from '@/store/transact/types'

// function removeExcluded(array, excludedIds) {
//   let ids = excludedIds.map(
//       (id) => {
//         return array.findIndex((item) => item.transactid === id)
//       }
//   ).filter((idx) => idx !== -1)
//   for (let i in ids) {
//     console.log(i)
//     console.log(ids[i])
//     array = array.splice(ids[i],1)
//   }
//   return array
// }
//
function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

export default {
    name: 'Categorize',
    components: {
      MonthSelector,
      CategoryList,
      TransactList
    },
    props: {
      transactList: {
        type: Array,
        default: function() {return []}
      }
    },
    computed: {
      refreshing() {
        return this.$store.getters[`${[TransactGetter.GetTransactListRunning]}`]
      },
      finpicProfile() {
        return this.$store.getters[`${[ProfileGetter.getFinpicProfile]}`]
      },
      activeFinpic() {
        let item = this.$store.getters[`${[ProfileGetter.getActiveFinpic]}`]
        console.log(item)
        return item
      },
      instanceList() {
        const baseList = this.$store.getters[`${[InstanceGetter.GetMonthlyInstanceList]}`]
        return baseList.map(
            (inst) => {
              return {id: inst.instanceid, date: new Date(inst.effective + ' 00:00:00')}
            }
        )
      },
      activeInstance() {
        return this.$store.getters[`${[InstanceGetter.GetActiveInstance]}`]
      },
      monthlyCategoryList() {
        let rawList = this.$store.getters[`${[CategoryGetter.GetMonthlyCategoryList]}`]
        rawList = rawList.filter(
            (x) => !!x.budgetcategoryinstanceid
        )
        rawList = rawList.map(
            (item) => {
              // initialize keys
              if (!this.categorize[item.name]) {
                this.categorize[item.name] = [];
              }
             return item
            })
        return rawList
      },
    },
  methods: {
    // TODO: BIG - A way to generalize the data model for "many" lists that we modify directly... transactList, categorize, holding lists, etc.

    // TODO: load account info stuff...
    // TODO: a way to provide more UI clarity around which category something will be dropped in
    refresh: function() {
      // TODO: check to be sure that this is desired!
      // if a category has data in it, ask whether you want to cancel, save, or refresh/clear
      this.$emit("refresh")
    },
    // TODO: Future
    // - allow seeing which things are already categorized (and where... what months, etc.)
    // - allow splitting a transaction into two (or more)
    // - allow clicking on a transaction and editing its contents (along with its "friends" / splits)
    save: function() {
      // TODO: a way to handle when things were partially completed before...

      console.log(this.categorize)
      this.submitted = true
      let any_entries = Array.from(Object.values(this.categorize)).some(
          (val) => {
            return val && val.length > 0
          }
      )
      if (!any_entries) {
        // show modal - "Nothing to save..."
        console.log("Nothing to see here!!")
        return
      }

      let res = new Promise((resolve) => {
        return Promise.all(
            Array.from(Object.entries(this.categorize)).map(
                ([key,value]) => {
                  // get category info
                  console.log(key)
                  let category = this.monthlyCategoryList.filter(
                      (item) => {
                        return item.name === key
                      }
                  )
                  if (category.length === 0) {
                    // could not find category... moving on...
                    // TODO: a way to surface such errors?
                    return "Error finding category"
                  }
                  let budget_category_instance_id = category[0].budgetcategoryinstanceid

                  // loop over transacts
                  return new Promise((resolve_inner) => {
                    return Promise.all(value.map(
                        (t) => {
                          // build budget transact and submit!!
                          // FUTURE: budgettransactlinkid if editing...?
                          // TODO: this is ONLY safe (ignoring linkid) because we hide "existing" budget transacts
                          // - otherwise, it will just blindly "create a new one" and therefore could create duplicates
                          console.log(t)
                          let finpicid = this.activeFinpic.finpicid
                          let frombudgetcategoryinstanceid
                          let tobudgetcategoryinstanceid
                          if (t.amount < 0) {
                            frombudgetcategoryinstanceid = budget_category_instance_id
                            tobudgetcategoryinstanceid = this.finpicProfile.externalbudgetcategoryinstance
                          } else {
                            frombudgetcategoryinstanceid = this.finpicProfile.externalbudgetcategoryinstance
                            tobudgetcategoryinstanceid = budget_category_instance_id
                          }

                          let submitData = {
                            transactid: t.transactid,
                            frombudgetcategoryinstanceid: frombudgetcategoryinstanceid,
                            tobudgetcategoryinstanceid: tobudgetcategoryinstanceid,
                            amount: t.amount,
                            description: null,
                            finpicid: finpicid,
                          }

                          let res = this.$store.dispatch(
                              `${[BudgetTransactAction.UpsertBudgetTransact]}`,
                              submitData
                          )
                          return res
                        }
                    )).then(
                        () => {
                          resolve_inner()
                        }
                    ).catch(
                        () => {
                          resolve_inner()
                        }
                    )
                  })
                }
            )
        ).then(
            () => {
              resolve()
            }
        ).catch(
            () => {
              resolve()
            }
        )
      })

      // TODO: also need a "this file is complete" dialog...
      console.log(res)
      res.then(
          (result) => {
            this.submitted = false
            console.log(result);
            this.$emit("saveComplete")
          }
      ).catch(
          (err) => {
            this.submitted = false
            throw Error(`API Error: ${err}`)
          }
      )
    },
    newBlankInstance: function(input) {
      this.$store.dispatch(
          `${[InstanceAction.CreateInstance]}`,
          {
            finpicid: this.activeFinpic.finpicid,
            input_type: 'month',
            input_date: this.makeDateString(input.month),
          }
      )
    },
    newTemplateInstance: function(input) {
      this.$store.dispatch(
          `${[InstanceAction.CreateInstanceFromTemplate]}`,
          {
            finpicid: this.activeFinpic.finpicid,
            input_type: 'month',
            input_date: this.makeDateString(input.month),
            templateid: this.finpicProfileAlt.templateInstance,
          }
      )
    },
    closeTransact: function(evt) {
      let tmpExcl = this.excludedTransacts
      tmpExcl.push(evt.transactid)
      tmpExcl = tmpExcl.filter(onlyUnique)
      this.excludedTransacts = tmpExcl
    },
    clearExcluded: function() {
      this.excludedTransacts = []
    },
    back: function() {
      // TODO: protect against doing this if items need to be saved!
      this.$emit("back")
    },
    test: function() {
      console.log(this.categorize)
    },
    activateInstance: function(month) {
      this.$store.dispatch(`${[CategoryAction.FetchMonthlyCategoryList]}`, {instanceid: month.id})
    },
    changeHandler: function(event, name) {
      if (event?.added) {
        console.log(name)
        console.log("Added: " + JSON.stringify(event.added.element))
        // TODO: find a way to process this transaction "live"
        // TODO: a way to sync the local state with the database state somehow...?

        // TODO: rather than re-fetch everything, we should probably use the response to surgically update the data model locally...?
      }
    },
    testEvent: function(event, name) {
        console.log(event)
      this.categorize[name] = event
      console.log(this.categorize[name])
    },
    removeCompletedBudgetTransacts: function(input) {
      // TODO: need to count and display how many were excluded, etc... a way to see them...?
      let res = input.filter(
          (item) => {
            if (item.budgets) {
              if (item.budgets.length > 0) {
                console.log("Removing item for budgets present!")
                console.log(item)
                return false
              }
            }
            return true
          }
      )
      return res
    },
    resetCategorize: function() {
      console.log("Resetting categories to be empty!")
      Array.from(Object.keys(this.categorize)).map(
          (key) => {
            this.categorize[key].splice(0, this.categorize[key].length)
          }
      )
      console.log(this.categorize)
    },
    reconcileExcludedHolding: function() {
      if (this.excludedTransacts.length > 0) {
        // ensure that excluded elements are in holding
        for (let idIndex in this.excludedTransacts) {
          let foundIt = this.internalTransactListDisplay.findIndex(
              (item) => {return item.transactid === this.excludedTransacts[idIndex]}
          )
          if (foundIt !== -1) {
            let elt = this.internalTransactListDisplay.splice(foundIt, 1)
            // only add the element if not a duplicate (in one of the holding areas)
            if (
                this.holdingExcluded.findIndex((item) => {return item.transactid === elt[0].transactid}) === -1 &&
                this.holdingInstance.findIndex((item) => {return item.transactid === elt[0].transactid}) === -1 &&
                this.holdingTagged.findIndex((item) => {return item.transactid === elt[0].transactid}) === -1
            ) {
              this.holdingExcluded.push(elt[0])
            }
          }
        }

        // ensure that not-excluded elements are not in holding
        // IMPORTANT: reverse index because otherwise we step on ourselves by modifying the array
        for (let i = this.holdingExcluded.length-1; i >= 0; i--) {
          let foundIt = this.excludedTransacts.indexOf(this.holdingExcluded[i].transactid)
          if (foundIt === -1) {
            // move it back into display
            let elt = this.holdingExcluded.splice(foundIt, 1)
            this.internalTransactListDisplay.push(elt[0])
          }
        }
      } else {
        if (this.holdingExcluded.length > 0) {
          // ensure that all items are moved back
          this.internalTransactListDisplay.push(...this.holdingExcluded.splice(0,this.holdingExcluded.length))
        }
      }
    },
    reconcileTaggedHolding: function() {
      if (this.filterOutTagged) {
        // move items that are tagged into holding
        // IMPORTANT: reverse index because otherwise we step on ourselves by modifying the array
        for (let i = this.internalTransactListDisplay.length-1; i >= 0; i--) {
          // if tagged...
          if (
              this.internalTransactListDisplay[i].tags.length > 0
          ) {
            let elt = this.internalTransactListDisplay.splice(i, 1)
            // only add the element if not a duplicate (in one of the holding areas)
            if (
                this.holdingExcluded.findIndex((item) => {return item.transactid === elt[0].transactid}) === -1 &&
                this.holdingInstance.findIndex((item) => {return item.transactid === elt[0].transactid}) === -1 &&
                this.holdingTagged.findIndex((item) => {return item.transactid === elt[0].transactid}) === -1
            ) {
              this.holdingTagged.push(elt[0])
            }
          }
        }

        // move items that are in holding, but not tagged, back to display
        // IMPORTANT: reverse index because otherwise we step on ourselves by modifying the array
        for (let i = this.holdingTagged.length-1; i >= 0; i--) {
          if (
              this.holdingTagged[i].tags.length === 0
          ) {
            let elt = this.holdingTagged.splice(i, 1)
            this.internalTransactListDisplay.push(elt[0])
          }
        }
      } else {
        // clear out holding
        if (this.holdingTagged.length > 0) {
          this.internalTransactListDisplay.push(...this.holdingTagged.splice(0, this.holdingTagged.length))
        }
      }
    },
    reconcileInstanceHolding: function() {
      if (this.filterToMonth) {
        // move items outside of month into holding
        // IMPORTANT: reverse index because otherwise we step on ourselves by modifying the array
        for (let i = this.internalTransactListDisplay.length-1; i >= 0; i--) {
          // if outside of the instance that is active
          if (
              this.internalTransactListDisplay[i].transactdate <= this.activeInstance.effective ||
              this.internalTransactListDisplay[i].transactdate >= this.activeInstance.terminate
          ) {
            console.log(this.internalTransactListDisplay[i].transactdate)
            console.log(this.activeInstance.effective)
            console.log(this.activeInstance.terminate)
            let elt = this.internalTransactListDisplay.splice(i, 1)
            // only add the element if not a duplicate (in one of the holding areas)
            if (
                this.holdingExcluded.findIndex((item) => {return item.transactid === elt[0].transactid}) === -1 &&
                this.holdingInstance.findIndex((item) => {return item.transactid === elt[0].transactid}) === -1 &&
                this.holdingTagged.findIndex((item) => {return item.transactid === elt[0].transactid}) === -1
            ) {
              this.holdingInstance.push(elt[0])
            }
          }
        }

        // move items in holding, but inside month, back to display
        // IMPORTANT: reverse index because otherwise we step on ourselves by modifying the array
        for (let i = this.holdingInstance.length-1; i >= 0; i--) {
          if (
              this.holdingInstance[i].transactdate >= this.activeInstance.effective &&
              this.holdingInstance[i].transactdate <= this.activeInstance.terminate
          ) {
            let elt = this.holdingInstance.splice(i, 1)
            this.internalTransactListDisplay.push(elt[0])
          }
        }
      } else {
        // clear out holding
        if (this.holdingInstance.length > 0) {
          this.internalTransactListDisplay.push(...this.holdingInstance.splice(0, this.holdingInstance.length))
        }
      }
    },
    computeInternalTransactList: function(input) {
      let newList = this.removeCompletedBudgetTransacts(_.cloneDeep(input))
      this.internalTransactListLength = newList.length
      this.internalTransactList = newList
      this.internalTransactListDisplay = _.cloneDeep(newList)

      this.reconcileInstanceHolding()
      this.reconcileExcludedHolding()

      // clear out "categorize"...
      // TODO: this may be a terrible idea...
      this.resetCategorize()
    },
  },
  watch: {
    activeInstance: function(val) {
      this.$store.dispatch(
          `${[TransactAction.CategoryInstanceList]}`,
          {instanceid: val.instanceid}
      )
      this.reconcileInstanceHolding()
    },
    filterToMonth: function() {
      this.reconcileInstanceHolding()
    },
    excludedTransacts: function() {
      this.reconcileExcludedHolding()
    },
    transactList: function(val) {
      this.computeInternalTransactList(val)
    }
  },
  mounted() {
    let activate = null;
    let res = this.$store.dispatch(`${ProfileAction.loadUserProfile}`)
    this.$store.dispatch(`${[TransactAction.DistinctTransactTags]}`)

    res.then(() => {
      if (!this.activeInstance.instanceid) {
        // TODO: check for instanceid to be 'Template'? That is not allowed...
        activate = 'latest'
      }
      this.$store.dispatch(
          `${[InstanceAction.FetchMonthlyInstanceList]}`,
          {activate: activate, finpicid: this.activeFinpic.finpicid}
          );

      this.computeInternalTransactList(this.transactList)
    })
  },
  data: () => ({
    filterToMonth: true,
    filterOutTagged: true,

    submitted: false,
    selected: [],

    // for all of the different categories
    categorize: {},

    // "holding" lists for where to hide things we may want later
    excludedTransacts: [],
    holdingExcluded: [],
    holdingInstance: [],
    holdingTagged: [],

    internalTransactListLength: 0,
    // copy of transactList prop (to isolate us from the parent component)
    // allows us to "go back"
    internalTransactList: [],
    // copy of transactList prop (which we actually use / display)
    internalTransactListDisplay: [],
  })
}
</script>

<style scoped>
</style>
