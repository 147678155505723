<template>
  <div>
    <v-menu
        v-model="menu"
        transition="scale-transition"
        origin="top left"
        bottom
        right
        :close-on-content-click="false"
    >
      <template v-slot:activator="{ on }">
        <v-chip v-if="transact" class="transact-chip" v-on="on" :close="close" @click:close="closeEvent(transact.transactid)">
          <p>
            <b>{{ transact.name.substring(0,titleLength).trim() }}{{ maybeDots(transact.name) }}</b>
            <br>
            ({{ transact.transactdate }})
            {{ transact.amount }}
          </p>
        </v-chip>
      </template>
      <!-- TODO: Make the v-card menu editable and pretty! -->
      <!-- TODO: a text input for budgeting and tagging easily -->
      <v-card width="300" min-height="100">
        <v-list>
          <v-list-item>{{ transact.name }} {{ transact.date }}</v-list-item>
          <v-list-item>{{ transact.amount }}</v-list-item>
          <v-list-item>TransactID: {{ transact.transactid }}</v-list-item>
          <v-list-item v-if="transact.budgets.length <= 1">
            <!-- TODO: Need to ignore this more stylishly if multiple present... -->
            <v-select
              @click.stop="doNothing()"
              @change="updateTransact()"
              label="Budget"
              :items="getCategoryInstanceList()"
              v-model="transactCategoryInstance"
              item-text="budgetcategory.name"
              item-value="budgetcategoryinstanceid"
              >
              <!-- TODO: we do not DO anything with this data yet... -->
              <template v-slot:selection="{ item }">
                <v-list-item-content>
                  <v-list-item-title v-text="item.budgetcategory.name" />
                  <v-list-item-subtitle v-text="item.instance.name" />
                </v-list-item-content>
              </template>
              <template v-slot:item="{ item }">
                <v-list-item-content>
                  <v-list-item-title v-text="item.budgetcategory.name" />
                  <v-list-item-subtitle v-text="item.instance.name" />
                </v-list-item-content>
              </template>
            </v-select>
          </v-list-item>
          <v-list-item>
            <v-combobox
                @click.stop="doNothing"
                label="Tags"
                v-model="transactTags"
                :items="tagList"
                item-text="tag.value"
                multiple
                small-chips
                @blur="updateTransact"
            />
          </v-list-item>
          <v-list-item-action><v-btn @click="selectTransact(transact)">Edit</v-btn></v-list-item-action>
        </v-list>
      </v-card>
    </v-menu>
    <v-dialog
      v-model="editTransactDialog"
      max-width="800px" persistent
      v-if="activeTransact && activeTransact.transactid"
      >
      <transact-form
          :instance-id="activeInstance.instanceid"
          :input-type="activeTransact.transactType"
          :id="activeTransact.transactid.toString()"
          :name="activeTransact.name"
          :description="activeTransact.description"
          :date="activeTransact.date"
          :datePost="activeTransact.datePost"
          :amount="activeTransact.amount"
          :tags="activeTransact.tags"
          :to-account="activeTransact.toAccount"
          :from-account="activeTransact.fromAccount"
          :transact-attr-link-id="activeTransact.primarytransactattrlink[0].transactattrlinkid.toString()"
          :budgets="activeTransact.budgets"
          :key="activeTransact.transactid"
          v-on:cancel="editTransactDialog = false"
          v-on:save="saveTransact"
      />
    </v-dialog>
  </div>
</template>

<script>
import {Action, Getter} from '@/store/transact/types'
import {Getter as InstanceGetter} from '@/store/instance/types'
import TransactForm from "@/components/TransactForm";

import _ from 'lodash'
import {Getter as ProfileGetter} from "@/store/profile/types";

export default {
  name: 'TransactChip',
  components: {TransactForm},
  data: () => ({
    menu: false,
    editTransactDialog: false,
    transactCategoryInstance: null,
    transactTags: [],
  }),
  mounted() {
    this.setTransactCategoryInstance()
    this.setTransactTags()
  },
  watch: {
    transact: function(val) {
      this.setTransactCategoryInstance(val)
      this.setTransactTags(val)
    },
  },
  methods: {
    setTransactTags: function(val) {
      if (!val) {
        val = this.transact
      }
      if (this.transact.tags.length > 0) {
        this.transactTags = this.transact.tags.map((elt) => elt.value)
      }
    },
    setTransactCategoryInstance: function(val) {
      if (!val) {
        val = this.transact
      }
      if (this.transact.budgets.length === 1) {
        if (this.transact.transactType === 1) {
          // income
          this.transactCategoryInstance = val.budgets[0].toCategoryInstance
        } else if (this.transact.transactType === 0) {
          // purchase
          this.transactCategoryInstance = val.budgets[0].fromCategoryInstance
        } else {
          // do nothing
        }
      }
    },
    updateTransact: function() {
      let tmpTransact = _.cloneDeep(this.transact)
      tmpTransact.tags = this.transactTags.map(
          (elt) => {
            // NOTE: combo-box does not work with item-value
            // https://github.com/vuetifyjs/vuetify/issues/5479
            if (elt?.tag?.value) {
              return elt.tag.value
            }
            return elt
          }
      )
      if (this.transact.budgets.length <= 1) {
        let tmpBudget = {}
        if (this.transact.budgets.length === 1) {
          tmpBudget = this.transact.budgets[0]
        } else {
          tmpBudget = {
            amount: this.transact.amount,
            description: null,
            tags: [],
            finpicid: this.finpicProfile.finpicid,
          }
        }
        // TODO: issue where we could overwrite a transaction...
        // that had a different "FROM" / "TO" / "external" target...
        // ugh
        if (this.transact.transactType === 0) {
          // purchase
          if (this.transactCategoryInstance && this.transactCategoryInstance !== tmpBudget.fromCategoryInstance) {
            tmpBudget.fromCategoryInstance = this.transactCategoryInstance
            tmpBudget.toCategoryInstance = this.finpicProfile.externalbudgetcategoryinstance
            tmpTransact.budgets[0] = tmpBudget
          }
        } else if (this.transact.transactType === 1) {
          // income
          if (this.transactCategoryInstance && this.transactCategoryInstance !== tmpBudget.toCategoryInstance) {
            tmpBudget.toCategoryInstance = this.transactCategoryInstance
            tmpBudget.fromCategoryInstance = this.finpicProfile.externalbudgetcategoryinstance
            tmpTransact.budgets[0] = tmpBudget
          }
        } else {
          // transfer - do nothing
        }
      }
      this.saveTransact(tmpTransact)
    },
    doNothing: function() {
    },
    getCategoryInstanceList: function() {
      return this.allCategoryList
    },
    maybeDots: function(input) {
      if (input.length > this.titleLength) {
        return "..."
      }
    },
    selectTransact: function(transact) {
      this.editTransactDialog = true
      this.$store.dispatch(`${[Action.ActivateTransact]}`, {transactid: transact.transactid})
    },
    closeEvent: function(id) {
        this.$emit("close", {transactid: id})
    },
    saveTransact: function(transact) {
      // TODO - would be great to check for duplicates here...
      console.log(transact)

      let res = this.$store.dispatch(`${[Action.UpsertTransact]}`, {...transact, finpicid: this.finpicProfile.finpicid})
      res.then(
          () => {
            // on success, emit "refresh" event with transact information
            this.$emit('refresh', transact)
          }
      )
      // TODO - catch errors!
      this.dialog = false
      this.editTransactDialog = false
    },
  },
  computed: {
    finpicProfile() {
      return this.$store.getters[`${[ProfileGetter.getFinpicProfile]}`]
    },
    activeTransact: function() {
      return this.$store.getters[`${[Getter.GetActiveTransact]}`]
    },
    activeInstance: function() {
      return this.$store.getters[`${[InstanceGetter.GetActiveInstance]}`]
    },
    allCategoryList: function() {
      return this.$store.getters[`${[Getter.GetAllCategoryInstanceList]}`]
    },
    tagList() {
      return this.$store.getters[`${[Getter.GetDistinctTransactTags]}`]
    },
  },
  props: {
    transact: {
        type: Object
    },
    titleLength: {
        type: Number,
        default: 20
    },
    close: {
        type: Boolean,
      default: true,
    },
  }
}
</script>

<!-- Could be "style scoped", but lots of these, feels redundant -->
<style>
.v-application p {
    margin-bottom: 0px;
}
.transact-chip > .v-card__title {
    padding-bottom: 0px;
}

.transact-chip > .v-card__subtitle, .v-card__text, .v-card__actions {
    padding-bottom: 0px;
    padding-top: 0px;
}

.transact-chip.v-chip.v-size--default {
  height: 38px;
}
</style>
