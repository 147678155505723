<template>
    <div>
        <v-dialog v-model="dialog" max-width="500px">
            <v-card>
                <v-card-title>
                    <span class="headline">Add Month</span>
                </v-card-title>

                <v-card-text>
                    Adding a budget month for <b>{{ formatMonthLong(newMonth) }}</b>
                    <br>
                    <br>
                    Should we start from a blank month, or from your
                    budget template?
                </v-card-text>

                <v-card-actions>
                    <v-spacer />
                    <v-btn color="primary" text @click="close">
                        Cancel
                    </v-btn>
                    <v-btn color="primary" text @click="saveBlank">
                        Blank
                    </v-btn>
                    <v-btn color="primary" text @click="saveTemplate">
                        Template
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-row>
            <v-spacer />
            <v-slide-group
                show-arrows="always"
                center-active
                mandatory
                v-model="activeMonthIndex"
            >
                <v-slide-item v-if="allowAdd">
                    <v-btn
                      icon
                      :disabled="disabled"
                      @click="addBefore"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </v-slide-item>
                <v-slide-item
                    v-for="(mon,i) in inputMonths"
                    :key="i"
                >
                    <v-btn
                        @click="activate(i, mon)"
                        :input-value="i === activeMonthIndex"
                        active-class="green white--text"
                        :disabled="disabled"
                    >
                        {{ formatMonth(mon.date) }}
                    </v-btn>
                </v-slide-item>
                <v-slide-item v-if="allowAdd">
                    <v-btn
                      icon
                      :disabled="disabled"
                      @click="addAfter"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </v-slide-item>
            </v-slide-group>
            <v-spacer />
        </v-row>
    </div>
</template>

<script>
export default {
    name: 'MonthSelector',
    methods: {
        activate: function(idx, month) {
            this.activeMonthIndex = idx
            this.activeMonthInternal = month.id
            this.$emit('change', month)
        },
        close: function() {
            this.dialog = false;
        },
        saveBlank: function() {
            this.dialog = false;
            this.$emit('addBlank', {month: this.newMonth})
        },
        saveTemplate: function() {
            this.dialog = false;
            this.$emit('addTemplate', {month: this.newMonth})
        },
        zeroPad: function(input) {
          if (input < 10) {
            return("0" + input.toString())
          } else {
            return input.toString()
          }
        },
        dateToStr: function(input) {
          let dt1 = this.zeroPad(input.getDate())
          let mon1 = this.zeroPad(input.getMonth()+1)
          let yr1 = input.getFullYear()
          let hr1 = this.zeroPad(input.getHours())
          let min1 = this.zeroPad(input.getMinutes())
          let sec1 = this.zeroPad(input.getSeconds())
          return `${yr1}-${mon1}-${dt1}T${hr1}:${min1}:${sec1}`
        },
        strToDate: function(input) {
          let dt1 = parseInt(input.substring(8,10))
          let mon1 = parseInt(input.substring(5,7))
          let yr1 = parseInt(input.substring(0,4))
          let hr1 = parseInt(input.substring(11,13))
          let min1 = parseInt(input.substring(14,16))
          // TODO: a way to grab sub-seconds...?
          let sec1 = parseInt(input.substring(17,19))
          let date1 = new Date(yr1, mon1-1, dt1, hr1, min1, sec1)
          return date1
        },
        addAfter: function() {
            if (this.inputMonths.length > 0) {
                this.newMonth =
                  this.computeRelativeMonth(
                      this.inputMonths[this.inputMonths.length - 1].date,
                      1
                  )
            }
            this.dialog = true;
        },
        addBefore: function() {
            if (this.inputMonths.length > 0) {
                this.newMonth =
                    this.computeRelativeMonth(
                        this.inputMonths[0].date,
                        -1
                    )
            }
            this.dialog = true;
        },
        monthTransform: function(index, total) {
            return -total + index + 1
        },
        reverseMonthTransform: function(desiredIndex, total) {
            return desiredIndex + total - 1 - 1 // for base 0
        },
        formatMonth: function(inputDate) {
            // check for it being a date...?
            const monthNum = inputDate.getMonth()
            const yearNum = inputDate.getFullYear().toString().substr(2)
            if (monthNum === 0) return 'Jan-' + yearNum
            if (monthNum === 1) return 'Feb-' + yearNum
            if (monthNum === 2) return 'Mar-' + yearNum
            if (monthNum === 3) return 'Apr-' + yearNum
            if (monthNum === 4) return 'May-' + yearNum
            if (monthNum === 5) return 'Jun-' + yearNum
            if (monthNum === 6) return 'Jul-' + yearNum
            if (monthNum === 7) return 'Aug-' + yearNum
            if (monthNum === 8) return 'Sep-' + yearNum
            if (monthNum === 9) return 'Oct-' + yearNum
            if (monthNum === 10) return 'Nov-' + yearNum
            if (monthNum === 11) return 'Dec-' + yearNum
        },
        formatMonthLong: function(inputDate) {
            // check for it being a date...?
            const monthNum = inputDate.getMonth()
            const yearNum = inputDate.getFullYear().toString()
            if (monthNum === 0) return 'January ' + yearNum
            if (monthNum === 1) return 'February ' + yearNum
            if (monthNum === 2) return 'March ' + yearNum
            if (monthNum === 3) return 'April ' + yearNum
            if (monthNum === 4) return 'May ' + yearNum
            if (monthNum === 5) return 'June ' + yearNum
            if (monthNum === 6) return 'July ' + yearNum
            if (monthNum === 7) return 'August ' + yearNum
            if (monthNum === 8) return 'September ' + yearNum
            if (monthNum === 9) return 'October ' + yearNum
            if (monthNum === 10) return 'November ' + yearNum
            if (monthNum === 11) return 'December ' + yearNum
        },
        generateMonthList: function(inputDate = new Date()) {
            var monthArr = [];
            for (var i = -25; i < 2; i++) {
                monthArr.push(this.formatMonth(this.computeRelativeMonth(inputDate, i)))
            }
            return monthArr
        },
        computeRelativeMonth: function(inputDate, deltaMonth) {
            const currentYear = inputDate.getFullYear()
            const currentMonth = inputDate.getMonth()
            var outputYear = 0;
            var outputMonth = 0;
            if (deltaMonth > 0) {
                outputMonth = currentMonth + (deltaMonth % 12)
                if (outputMonth > 12) {
                    outputMonth = outputMonth - 12
                    outputYear = 1
                }
                outputYear = outputYear + Math.floor(deltaMonth / 12) + currentYear
            } else {
                outputMonth = currentMonth + (deltaMonth % 12)
                if (outputMonth < 0) {
                    // cycle to positive (mod 12)
                    outputMonth = outputMonth + 12
                    outputYear = -1
                }
                outputYear = outputYear + Math.ceil(deltaMonth / 12) + currentYear
            }
            console.log(outputYear + ' '  + outputMonth)
            return new Date(outputYear, outputMonth, 1)
        },
      setMonthIndex: function(month) {
        // TODO: figure out how to set up reactivity properly with the activeMonth list...
        // wait for activeInstance to be defined... if it is not in the list, set it to be last...
        // if nothing is selected... set it to be last... etc.
        const monthsIndices = this.inputMonths.map((m) => m.id)
        let newIndex = monthsIndices.indexOf(month)
        if (newIndex === -1 ) {
          if (monthsIndices.length > 0 ) {
            // default to "last"
            newIndex = monthsIndices.length - 1
          } else {
            newIndex = 0
          }
        }
        this.activeMonthIndex = newIndex
      }
    },
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        activeMonth: {
            type: Number,
            default: 0
        },
        allowAdd: {
            type: Boolean,
            default: true,
        },
        inputMonths: {
            type: Array,
            default: function() {
                return [
                    {id: 0, date: new Date()},
                    {id: 1, date: new Date('2021-01-15')},
                ]
            }
        }
    },
    mounted() {
        this.formSelectedMonth = this.reverseMonthTransform(this.selectedMonth, 26);
        this.activeMonthInternal = this.activeMonth
      this.setMonthIndex(this.activeMonth)
    },
    watch: {
        activeMonth: function(val) {
          console.log('Change: ' + val)
            this.activeMonthInternal = val
          this.setMonthIndex(val)
        }
    },
    data: () => ({
        newMonth: new Date(),
        dialog: false,
        model: null,
        formSelectedMonth: new Date(),
        activeMonthInternal: 0,
        activeMonthIndex: 0,
    }),
}
</script>
