<template>
  <div>
    <v-row style="padding-left: 20px; padding-right: 20px">
      <v-btn
          color="primary"
          @click="back"
          :disabled="matchRunning"
      >
        Back
      </v-btn>
      <v-spacer />
      <v-btn
          color="primary"
          @click="submit"
          :disabled="matchRunning"
      >
        Verify
      </v-btn>
    </v-row>
    <v-progress-linear indeterminate v-if="matchRunning" />
    <v-row style="margin-top: 12px;">
      <v-col cols="12" style="padding-bottom: 0px">
        <!-- TODO: info box / hover with more info -->
        <h2>Matching "{{ activeUploadFileName }}" with "{{ activeAccountName }}"</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-card style="margin-top: 5px; margin-left: 5px; padding-bottom: 20px;">
          <v-card-title>File Overview</v-card-title>
          <v-card-text>
            <ul>
              <li>{{ activeUploadFileRecs }} transactions</li>
              <li>{{ activeUploadFileMatched }}</li>
              <li v-html="activeUploadFileMatchedGroups"></li>
              <li>{{ activeUploadFileDateRange}}</li>
            </ul>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="8">
        <!-- TODO: summaries over statuses / selected / etc. -->
        <v-card style="margin-right: 5px; margin-top: 5px; padding-bottom: 12px;">
          <v-card-title>Matched Data</v-card-title>
          <v-card-text>
            TODO: Some way of presenting matched data
            <ul>
              <li>See file on left, account on right</li>
              <li>Order by date... or something?</li>
              <li>Almost like a data frame... but not?</li>
              <li>A way to present "multi-matches" and make selections?</li>
              <li>Filter to matched, pending, not matched</li>
            </ul>
          </v-card-text>
        </v-card>
        <v-card style="margin-right: 5px; margin-top: 10px; padding-bottom: 12px;">
          <v-card-title>Present</v-card-title>
          <v-card-text>
            <v-row>
              <v-btn-toggle
                  v-model="toggle_matches"
                  multiple
              >
                <v-btn :disabled="matchDataStatuses.indexOf(0) === -1">
                  <v-icon>mdi-database</v-icon>
                  New ({{ activeUploadFileMatchedCounts["0"] }})
                </v-btn>
                <v-btn :disabled="matchDataStatuses.indexOf(1) === -1">
                  <v-icon>mdi-database</v-icon>
                  Matched ({{ activeUploadFileMatchedCounts["1"] }})
                </v-btn>
                <v-btn :disabled="matchDataStatuses.indexOf(2) === -1">
                  <v-icon>mdi-database</v-icon>
                  Multi-Match ({{ activeUploadFileMatchedCounts["2"] }})
                </v-btn>
                <v-btn :disabled="matchDataStatuses.indexOf(3) === -1">
                  <v-icon>mdi-database</v-icon>
                  Already ({{ activeUploadFileMatchedCounts["3"] }})
                </v-btn>
              </v-btn-toggle>
            </v-row>
            <v-row>
              <v-btn-toggle v-model="exclude">
                <v-btn>Not-Perfect</v-btn>
              </v-btn-toggle>
            </v-row>
            <v-row>
              <v-btn-toggle>
                <v-btn @click="expandAll">Expand All</v-btn>
                <v-btn @click="collapseAll">Collapse All</v-btn>
              </v-btn-toggle>
            </v-row>
            <v-data-table
              :headers="tableHeaders"
              :items="matchDataResultFiltered"
              :single-expand="singleExpand"
              :expanded.sync="expanded"
              :search="searchText"
              show-expand
              item-key="uploadfilerecord.uploadfilerecordid"
              >
              <template v-slot:top>
                <v-text-field
                    v-model="searchText"
                    label="Search"
                    class="mx-4"
                ></v-text-field>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" style="padding-top:7px; padding-bottom:7px">
                  <!--More info about {{ item.uploadfilerecord.uploadfilerecordid }}-->
                  <!-- TODO: Colorize, warn if the match was to a different account... -->
                  <div v-if="item.status === 0">
                    No match found
                  </div>
                  <div v-if="item.status === 1">
                    Only one match found:
                  </div>
                  <div v-if="item.status === 2">
                    Choose a Transaction that matches:
                  </div>
                  <div v-if="item.status === 3">
                    Match already established.
                  </div>
                  <v-list v-if="item.status !== 0">
                    <v-list-item-group v-model="item.Selected">
                      <v-list-item v-for="(t,i) in item.transacts" :key="i">
                        <v-simple-table style="width:100%">
                          <template v-slot:default>
                            <tbody>
                              <td>{{t.transactdate}}</td>
                              <td>{{t.name}}</td>
                              <td>{{t.description}}</td>
                              <td>{{t.inneramount}}</td>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-list-item>
                      <v-list-item v-if="item.status !== 3" :key="-1">
                        <v-simple-table style="width:100%">
                          <template v-slot:default>
                            <tbody>
                              <td colspan="4">Create New Transaction</td>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                  <v-btn v-if="item.status === 3" @click="discardMatchRequest(item)">Discard Match</v-btn>
                </td>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-progress-linear indeterminate v-if="matchRunning" />
    <nothing-dialog v-model="nothingDialog" v-on:OK="nothingDialog = false"/>
    <v-dialog
        v-model="confirmMatchDeleteDialog"
        v-on:cancel="cancelConfirmation"
    >
      <v-card>
        <v-progress-linear v-if="discardRunning" indeterminate />
        <v-card-title>
          Are you sure you want to delete this match?
        </v-card-title>
        <v-card-text>
          {{ pendingDeleteMatch }}
        </v-card-text>
        <v-card-actions>
          <v-btn @click="cancelConfirmation" :disabled="discardRunning">Cancel</v-btn>
          <v-btn @click="discardMatch(pendingDeleteMatch)" :disabled="discardRunning">Yes. Delete</v-btn>
        </v-card-actions>
        <v-progress-linear v-if="discardRunning" indeterminate />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from 'vue'
import {Getter, Action} from "@/store/importHelper/types";
import {Getter as AccountGetter} from "@/store/account/types";
import {Getter as ProfileGetter} from '@/store/profile/types'
import NothingDialog from "@/components/NothingDialog";

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

function translateMatch(value) {
  if ( value === 0 || value === "0" ) {
    return "New Transaction"
  } else if ( value === 1 || value === "1" ) {
    return "Match Found"
  } else if ( value === 2 || value === "2" ) {
    return "Many Matches Found"
  } else if ( value === 3 || value === "3" ) {
    return "Match Already Established"
  } else {
    return "Unknown"
  }
}

export default {
  name: "ReviewMatch",
  components: {NothingDialog},
  data: () => ({
    toggle_matches: [],
    exclude: [],
    expanded: [],
    pendingDeleteMatch: {},
    confirmMatchDeleteDialog: false,
    nothingDialog: false,
    singleExpand: false,
    searchText: "",
    tableHeaders: [
      {text: 'Date', value: 'uploadfilerecord.date'},
      {text: 'Name', value: 'uploadfilerecord.name'},
      {text: 'Description', value: 'uploadfilerecord.description'},
      {text: 'Amount', value: 'uploadfilerecord.amount'},
      {text: '', value: 'data-table-expand'},
    ]
  }),
  computed: {
    matchRunning() {
      return this.$store.getters[`${[Getter.GetMatchUploadFileRecordsRunning]}`]
    },
    discardRunning() {
      return this.$store.getters[`${[Getter.GetDiscardMatchRunning]}`]
    },
    finpicProfile() {
      return this.$store.getters[`${[ProfileGetter.getFinpicProfile]}`]
    },
    activeAccountId: function() {
      let activeAccountId = this.$store.getters[`${[Getter.GetActiveAccountId]}`]
      return activeAccountId
    },
    activeAccountName: function() {
      let accountList = this.$store.getters[`${[AccountGetter.getAccountList]}`]
      if (accountList) {
        console.log("Try it?")
        let activeAccount = accountList.filter(
            (acc) => {return acc.accountid === this.activeAccountId}
        )
        if (activeAccount.length > 0) {
          return activeAccount[0].name
        }
      }
      return ''
    },
    matchData: function() {
      let dat = this.$store.getters[`${[Getter.GetMatches]}`]
      if (dat) {
        if (dat.result) {
          dat.result = dat.result.map(
              (item) => {
                if (!item.Selected && item.Selected !== 0) {
                  Vue.set(item, "Selected", null)
                }
                return item
              }
          )
        }
      }
      console.log(dat)
      return dat
    },
    matchDataResult: function() {
      if (this.matchData) {
        if (this.matchData.result) {
          return this.matchData.result
        }
      }
      return []
    },
    matchDataResultFiltered: function() {
      let dat = this.matchDataResult
      let res = []
      if (this.toggle_matches.length === 0) {
        // do nothing
        res = dat
      } else {
        res = dat.filter(
            (item) => {
              return this.toggle_matches.indexOf(item.status) !== -1
            }
        )
      }

      console.log(this.exclude)
      // TODO: improve if we add exclusions
      if ((!this.exclude && this.exclude !== 0) || this.exclude.length === 0) {
        // do nothing
      } else {
        res = res.filter(
            (item) => {
              return (item.status !== 3 && item.status !== 1) || (
                  item.uploadfilerecord.rawrecord.Description !== item.transacts[0].name &&
                  item.uploadfilerecord.rawrecord.Description !== item.transacts[0].description
              )
            }
        )
      }

      return res
    },
    activeUploadFile: function() {
      let act = this.$store.getters[`${[Getter.GetActiveUploadFile]}`]
      return act
    },
    activeUploadFileName: function() {
      if (this.activeUploadFile) {
        let act = this.activeUploadFile
        return act.filename
      }
      return ""
    },
    activeUploadFileRecs: function() {
      let act = this.activeUploadFile
      if (act) {
        if (act.uploadfilemeta) {
          if (act.uploadfilemeta.length > 0) {
            return act.uploadfilemeta[0].recs
          }
        }
      }
      return 0
    },
    activeUploadFileMatched: function() {
      if (!this.matchData) { return "No records found" }
      let md = this.matchDataResult
      console.log(md)
      if (md) {
        let matched = md.filter((item) => {return item.status !== 0})
        let matchCount = matched.length
        let totalRecs = this.activeUploadFileRecs
        let percentage = 0
        if (totalRecs > 0) {
          percentage = matchCount / totalRecs * 100
          return matchCount + " matched (" + percentage + "%)"
        }
      }
      return "No records found"
    },
    matchDataStatuses: function() {
      if (!this.matchData) { return []}
      let md = this.matchDataResult
      let statuses = []
      if (md) {
        statuses = md.map((item) => {return item.status}).filter(onlyUnique)
      }
      return statuses
    },
    activeUploadFileMatchedCounts: function() {
      let outputData = {"0": 0, "1": 0, "2": 0, "3": 0}
      if (!this.matchData) { return "" }
      let md = this.matchDataResult
      if (md) {
        let statuses = this.matchDataStatuses
        for (let i in statuses) {
          let count = md.filter((item) => {return item.status === statuses[i]}).length
          outputData[statuses[i]] = count
        }
      }
      return outputData
    },
    activeUploadFileMatchedGroups: function() {
      if (!this.matchData) { return "" }
      let md = this.matchDataResult
      let c = this.activeUploadFileMatchedCounts
      let outputString = ""
      if (md) {
        for (let i in c) {
          if (outputString != "") {
            outputString = outputString + "<br>"
          }
          outputString = outputString + translateMatch(Object.keys(c)[i]) + ": " + c[i]
        }
      }
      return outputString
    },
    activeUploadFileDateRange: function() {
      // let md = this.matchData.result
      // TODO: determine minimum and maximum date
      return "1/1/2020 - 1/31/2020"
    },
  },
  methods: {
    discardMatchRequest: function(match) {
      Vue.set(this, 'pendingDeleteMatch', match)
      this.confirmMatchDeleteDialog = true
    },
    discardMatch: function(match) {
      console.log(match)
      let res = this.$store.dispatch(
          `${[Action.DiscardMatch]}`,
          {
            uploadfilerecordtransactid: match.uploadfilerecord.uploadfilerecordtransactid,
            transactid: match.transactid
          })

      res.then(
          () => {
            // TODO: show success
            this.cancelConfirmation()

            // TODO: a way to "refresh" - right now we just "go back"
            this.$emit('discard', match)
          }
      )
    },
    cancelConfirmation: function() {
      Vue.set(this, 'pendingDeleteMatch', {})
      this.confirmMatchDeleteDialog = false
    },
    expandAll: function() {
      this.expanded = this.matchDataResultFiltered
    },
    collapseAll: function() {
      this.expanded = []
    },
    back: function() {
      this.$emit("back")
    },
    submittableTransact: function(match) {
      let toAccount
      let fromAccount
      let amount = match.uploadfilerecord.amount
      // create transaction with the contents
      if ( amount < 0 ) {
        toAccount = this.finpicProfile.externalaccount
        fromAccount = this.activeAccountId
        amount = -amount
      } else {
        toAccount = this.activeAccountId
        fromAccount = this.finpicProfile.externalaccount
      }
      return {
        transactid: null,
        uploadfilerecordid: match.uploadfilerecord.uploadfilerecordid,
        date: match.uploadfilerecord.date,
        name: match.uploadfilerecord.name,
        description: match.uploadfilerecord.description,
        amount: amount,
        fromAccount: fromAccount,
        toAccount: toAccount,
        datePost: null
      }
    },
    submit: function() {
      // TODO: error handling... alert and say "error - 2 transactions failed... more details...?
      // TODO: add a progress bar
      console.log('firing submit')
      // let prepArray = [].map(
      let prepArray = this.matchDataResult.map(
          (match) => {
            if (match.status === 0) {
              return this.submittableTransact(match)
            } else if (match.status === 1) {
              // match to transaction found
              if (match.Selected === (match.transacts.length-1 + 1)) {
                // allow creating a new transaction with a special key
                return this.submittableTransact(match)
              } else {
                return {
                  transactid: match.transacts[0].transactid,
                  uploadfilerecordid: match.uploadfilerecord.uploadfilerecordid
                }
              }
            } else if (match.status === 2 && (match.Selected || match.Selected === 0)) {
              // match to transaction selected
              if (match.Selected === (match.transacts.length-1 + 1)) {
                // allow creating a new transaction with a special key
                return this.submittableTransact(match)
              } else {
                return {
                  transactid: match.transacts[match.Selected].transactid,
                  uploadfilerecordid: match.uploadfilerecord.uploadfilerecordid
                }
              }
            }
          }
      ).filter(
          (elt) => {if (elt) return elt}
      )
      // TODO: verification
      // - that the same transaction was not selected twice as a match...
      // - that all necessary were selected... (i.e. multi-matches, etc.)
      console.log(prepArray)
      let res = this.$store.dispatch(`${[Action.MatchUploadFileRecords]}`, {matchArray: prepArray})
      res.then(
          () => { this.$emit("submit") }
          )
    }
  }
}
</script>

<style scoped>

</style>
