<template>
  <div style="width: 100%; min-height: 100px; padding-top: 20px;">
    <v-card>
      <v-card-title>
        Options
      </v-card-title>
      <v-card-text>
        <v-select
            :items="options"
            v-model="selected"
            label="Options"
            multiple
            item-text="name"
            item-value="id"
            v-on:change="change"
        >

        </v-select>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "ImportOptions",
  data: () => ({
    "selected": [3,5,7],
  }),
  props: {
    "options": {
      "type": Array,
      "default": function() {
        return [
          {"id": 1, "name": "Negative Credit"},
          {"id": 2, "name": "Negative Debit"},
          {"id": 3, "name": "Strip $"},
          {"id": 4, "name": "Strip ()"},
          {"id": 5, "name": "Flip the sign"},
        ]
      }
    }
  },
  methods: {
    change: function() {
      this.$emit('change', this.selected)
    },
  },
  mounted() {
    this.$emit('change', this.selected)
  }
}
</script>

<style scoped>

</style>
