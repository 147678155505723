<template>
  <div style="width: 100%; min-height:100px">
    <v-card style="padding-left: 5px; padding-right: 5px;">
      <v-card-title>
        Columns
      </v-card-title>
      <v-card-text>
        <v-row
            v-for="item in items"
            :key="item.name"
        >
          <v-select
              :items="columns"
              v-model="item.selected"
              item-text="name"
              :label="item.display"
              v-on:change="change"
          >
          </v-select>
        </v-row>
      </v-card-text>
    </v-card>
    <!--
    TODO: figure out draggable stuff...
    <v-row>
      <v-chip-group style="min-height:100px; width: 100%">
        <draggable
            :list="columns"
            :group="groupName"
        >
          <v-chip
              v-for="col in columns"
              :key="col.name"
          >
            {{ col.name }}
          </v-chip>
        </draggable>
      </v-chip-group>
    </v-row>
    <v-list>
      <v-list-item-group>
        <v-list-item
            v-for="item in items"
            :key="item.name"
            style="min-height: 100px; width: 100%"
        >
          <draggable
            :list="item.selected"
            :group="groupName"
            style="min-height:100px; width: 100%"
            >
            {{ item.name }}:
            <v-chip-group style="width: 100%">
              <v-chip v-for="col in item.selected" :key="col.name">
                {{ col.name }}
              </v-chip>
            </v-chip-group>
          </draggable>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    -->
  </div>
</template>

<script>
// TODO: form validation - big time!!

//import draggable from 'vuedraggable'
export default {
  name: "ColumnClassifier",
  components: {
    //draggable
  },
  props: {
    "items": {
      "type": Array,
      "default": function() {
        return [
          {"name": "date", "display": "Date", "selected": null},
          {"name": "name", "display": "Name", "selected": null},
          {"name": "description", "display": "Description", "selected": null},
          {"name": "amount", "display": "Amount", "selected": null},
          {"name": "credit", "display": "Credit", "selected": null},
          {"name": "debit", "display": "Debit", "selected": null},
        ]
      },
    },
    "columns": {
      "type": Array,
      "default": function() { return [{"name": "date"}, {"name": "description"}, {"name": "name"}] },
    },
    "groupName": String,
  },
  methods: {
    change: function() {
      this.$emit('change', this.items)
    }
  }
}
</script>

<style scoped>
div.v-chip-group {
  width: 100%;
  height: 100%;
  min-height: 100px;
}
</style>
