<template>
<v-card>
<v-list >
    <v-subheader><b>{{ listName }}</b></v-subheader>
  <div :style='"max-height: " + maxHeight + "px; overflow-y: scroll"'>
    <v-list-item-group>
      <v-container fluid>
        <draggable
            :list="value"
            :group="groupName"
            style="min-height: 50px;"
            class="row row--dense"
        >
          <template v-for="(transact, index) in value">
            <v-list-item :key="index" class="transact-list-item">
              <TransactChip
                  :transact="transact"
                  :title-length="titleLength"
                  v-on:close="close"
              />
            </v-list-item>
          </template>
        </draggable>
      </v-container>
    </v-list-item-group>
  </div>
</v-list>
</v-card>
</template>

<script>
import draggable from 'vuedraggable'
import TransactChip from '@/components/TransactChip.vue'
export default {
    name: "TransactList",
    components: {
        TransactChip,
        draggable
    },
    props: {
        value: Array,
        groupName: String,
        listName: String,
        titleLength: {
          type: Number,
          default: 20,
        },
      maxHeight: {
          type: Number,
        default: 350,
      }
    },
    methods: {
      close: function(evt) {
        this.$emit("close", evt)
      },
    }
}
</script>

<style>
.transact-list-item {
    flex: unset;
    padding: 0 0 2px 2px;
}
</style>
