<template>
    <div class="import">
        <h1>Import New Data!</h1>
        <v-container>
            <v-row justify="center">
                <div style="text-align:center">
                Use the three step process below to import a
                statement <br> from a bank or credit card company.
                </div>
            </v-row>
        </v-container>
        <v-stepper v-model="el" alt-labels>
            <v-stepper-header>
              <v-stepper-step v-bind:editable="step1edit" :complete="el > 1" step="1">
                Import File
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step v-bind:editable="step2edit" :complete="el > 2" step="2">
                Match Data
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step v-bind:editable="step3edit" step="3">
                Review Data
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step v-bind:editable="step4edit" step="4">
                Categorize Data
              </v-stepper-step>
            </v-stepper-header>
            <v-stepper-content step="1">
                <br>
                <v-row justify="center">
                    <h3>
                    Choose a new file or use a previously imported file.
                    </h3>
                </v-row>
                <br>
                <v-row style="padding-right:20px">
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        :disabled="disableSubmit"
                        v-on:click="submitFile"
                    >
                        Import
                    </v-btn>
                    <v-btn
                        color="primary"
                        @click="el = 2"
                        style="margin-left:10px"
                    >
                        Use Existing File
                    </v-btn>
                </v-row>
                <v-row>
                    <v-col
                      cols="12" sm="6" md="4"
                    >
                        <div style="text-center">
                        </div>
                        <br>
                        <v-file-input
                          placeholder="Upload a CSV"
                          v-bind:rules = "[checkFileType]"
                          v-model="importFile"
                          v-on:change="fileUpload"
                        >
                        </v-file-input>
                    </v-col>
                </v-row>
            </v-stepper-content>
            <v-stepper-content step="2">
                <br>
                <v-row justify="center">
                    <h3>
                        Match your file with your account
                    </h3>
                </v-row>
                <br>
                <ReviewFile
                    :imported-files="uploadFiles"
                    :accounts="accountList"
                    v-on:submit="handleFileReview"
                ></ReviewFile>
            </v-stepper-content>
            <v-stepper-content step="3">
              <br>
              <v-row justify="center">
                <h3>
                  Review matched file data
                </h3>
              </v-row>
              <br>
              <ReviewMatch
                  v-on:submit="verifyMatch"
                  v-on:back="el = 2"
                  v-on:discard="el = 2"
              >
              </ReviewMatch>
            </v-stepper-content>
          <v-stepper-content step="4">
            <v-row justify="center">
                    <h3>
                        Drag transactions to the appropriate
                        categories.
                    </h3>
                </v-row>
                <v-row justify="center">
                    <h3>
                        Save at any time!
                    </h3>
                </v-row>
                <br>
                <Categorize
                    :transact-list="serverTransactList"
                    v-on:back="el = 2"
                    v-on:saveComplete="saveCategorize"
                    v-on:refresh="refreshCategorize"
                ></Categorize>
            </v-stepper-content>
        </v-stepper>

    </div>
</template>

<script>
// @ is an alias to /src
import ReviewFile from '@/components/ReviewFile.vue'
import Categorize from '@/components/Categorize.vue'
import {Action, Getter} from '@/store/importHelper/types.js'
import {Action as AccountAction, Getter as AccountGetter} from '@/store/account/types.js'
import ReviewMatch from "@/components/ReviewMatch";
import {Action as TransactAction, Getter as TransactGetter} from "@/store/transact/types";
import {Getter as ProfileGetter} from '@/store/profile/types'

export default {
  name: 'Import',
  metaInfo: {
      title: 'Import',
  },
  components: {
    ReviewFile,
    ReviewMatch,
    Categorize,
  },
  data: () => ({
    disableSubmit: true,

    activeAccount: null,

    el: 1,
    dialog: false,
    importFile: null,
    dataImport: null,
    step1edit: true,
    step2edit: true,
    step3edit: false,
    step4edit: false,

    fileObject: null,
    fileName: null,
  }),

  computed: {
    activeUploadFile: function() {
      let act = this.$store.getters[`${[Getter.GetActiveUploadFile]}`]
      return act
    },
    activeUploadFileName: function() {
      if (this.activeUploadFile) {
        let act = this.activeUploadFile
        return act.filename
      }
      return ""
    },
    activeFinpic() {
      return this.$store.getters[`${[ProfileGetter.getActiveFinpic]}`]
    },
    accountList() {
      return this.$store.getters[`${[AccountGetter.getAccountList]}`]
    },
    uploadFiles() {
      let dat = this.$store.getters[`${[Getter.GetUploadFiles]}`]
      if (dat) {
        dat.sort((x,y) => {
          // TODO: fix sorting to actually parse dates
          // console.log(new Date(x))
          // console.log(new Date(y))
          if (x < y) {
            return 1
          }
          return -1
        })
      }
      return dat
    },
    serverTransactList: function() {
      console.log("Trying to get server transact list")
      let tl = this.$store.getters[`${[TransactGetter.GetTransactList]}`]
      console.log(tl)
      return tl
    },
  },

  created() {
    this.initialize()
  },

  methods: {
    initialize: function() {
      this.$store.dispatch(`${[Action.FetchUploadFiles]}`)
      this.$store.dispatch(`${[AccountAction.loadAccountList]}`, {finpicid: this.activeFinpic.finpicid})
      this.$store.dispatch(`${[Action.GetMatchOptions]}`)
    },
    "fileUpload": function(x) {
      console.log('hi')
      console.log(x)
      this.fileObject = x;
      this.fileName = x.name
      this.disableSubmit = false
    },
    "handleFileReview": function(input) {
      this.activeAccount = input.accountid
      this.el = 3
    },
    "submitFile": function() {
      console.log('Submitting')
      // TODO: check for duplicates...
      this.$store
          .dispatch(`${[Action.UploadFile]}`, {filename: this.fileName, file: this.fileObject})
          .then(() => {
            // TODO: check for success...?
            console.log('Done submitting file')
            this.$store.dispatch(`${[Action.FetchUploadFiles]}`)
            this.el = 2
          })
    },
    "showDialog": function() {
        this.dialog = true
    },
    "checkFileType": function(inputFile) {
        if (inputFile) {
              // TODO: better file type check
          if (inputFile.type=="text/csv") {
              return true
          } else {
              return "Please upload a CSV"
          }
        } else {
            // no file
            return true
        }
    },
    saveCategorize: function() {
      this.fetchTransactList()
    },
    refreshCategorize: function() {
      this.fetchTransactList()
    },
    fetchTransactList: function() {
      if (this.activeUploadFile) {
        // TODO: do we need a way to re-trigger this...? if activeUploadFile changes...?
        this.$store.dispatch(`${[TransactAction.TransactList]}`, {uploadfileid: this.activeUploadFile.uploadfileid, accountid: this.activeAccount})
      }
    },
    verifyMatch: function() {
      this.el = 4
      this.fetchTransactList()
    },
  },
}
</script>
